import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Section from "components/Section";
import { useHeaderVisible } from "hooks";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Heading,
    ActionEntries,
    ActionEntry,
} from "./ActionEntryPointList.styled";
import ActionEntryPointListProps from "./ActionEntryPointListProps";

const ActionEntryPointList = ({
    identifier,
    theme,
    contentTheme,
    actionTheme,
    heading,
    displayOption = "Left",
    actionEntries,
    anchorName,
}: ActionEntryPointListProps): ReactElement => {
    const headerVisible = useHeaderVisible();

    return (
        <Section id={anchorName} theme={theme} alignItems="center">
            <ThemeProvider
                theme={{
                    theme: theme,
                    contentTheme: contentTheme,
                    actionTheme: actionTheme || contentTheme,
                    displayOption: displayOption,
                }}
            >
                <Container key={identifier || `ActionEntryPointListContent`}>
                    <Heading
                        {...addEditAttributes("Heading")}
                        $headerVisible={headerVisible}
                    >
                        {heading}
                    </Heading>
                    <ActionEntries {...addEditAttributes("ActionEntries")}>
                        {actionEntries?.map((actionEntry, index) => {
                            return (
                                <ActionEntry
                                    key={`ActionEntryPointList${index}`}
                                    id={actionEntry.identifier}
                                    variant="primary"
                                    size="large"
                                    gtmClickType="cta"
                                    text={
                                        actionEntry.text || actionEntry.linkText
                                    }
                                    icon={actionEntry.icon}
                                    url={actionEntry.url}
                                />
                            );
                        })}
                    </ActionEntries>
                </Container>
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(ActionEntryPointList);
