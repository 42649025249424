import { styled } from "styled-components";
import theme from "styled-theming";

import Button from "components/Button";
import { Container as GridContainer } from "style/components/Page";
import { styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { topPadOffset } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${columnGap};
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    position: relative;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Heading = styled.h2.attrs<{
    $headerVisible: boolean;
}>(() => ({}))`
    ${styleHeadingXL};
    grid-column: ${theme("displayOption", {
            Left: "1",
            Right: "2",
        })} / span 3;
    grid-row: 1;
    justify-self: ${theme("displayOption", {
        Left: "flex-start",
        Right: "flex-end",
    })};
    margin: 0;
    text-align: ${theme("displayOption", {
        Left: "left",
        Right: "right",
    })};

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
                Left: "1",
                Right: "5",
            })} / span 8;
    }

    ${MQ.FROM_L} {
        align-self: start;
        grid-column: ${theme("displayOption", {
                Left: "1",
                Right: "9",
            })} / span 4;
        position: sticky;
        top: 4.375rem;
        ${topPadOffset};
    }

    ${MQ.FROM_XL} {
        grid-column: ${theme("displayOption", {
                Left: "1",
                Right: "7",
            })} / span 6;
    }
`;

export const ActionEntries = styled.div`
    display: grid;
    flex-flow: column;
    grid-column: 1 / span 4;
    grid-row: 2;
    margin-top: 2.1875rem;
    row-gap: 1.3125rem;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
                Left: "5",
                Right: "2",
            })} / span 8;
        margin-top: 3.9375rem;
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
                Left: "7",
                Right: "2",
            })} / span 5;
        grid-row: 1;
        margin-top: 3.9375rem;
    }

    ${MQ.FROM_XL} {
        margin-top: 5.25rem;
        row-gap: 1.75rem;
    }
`;

export const ActionEntry = styled(Button)`
    padding-left: 0.875rem;
    padding-right: 0.875rem;
`;
